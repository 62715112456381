import React, { useState, useRef, useEffect } from "react";
import DomainListing from "./domainListing";
import axios from "axios";
import Loader from "react-loader-spinner";
import { Search } from 'lucide-react';
import PropTypes from "prop-types";

// eslint-disable-next-line no-unused-vars
const isValidDomain = require("is-valid-domain");

function HomeDomainSearchInput(props) {
  const [title, setTitle] = useState("");
  const [domainList, setDomainList] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [loading, setLoading] = useState(false);
  const searchRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setHasSearched(false);
    }
  };

  const fetchDomains = async () => {
    setLoading(true);
    setDomainList([]); // Clear previous results

    try {
      const response = await axios.get("https://domain-api.softylus.com/getAva", {
        params: { domainName: title },
      });

      const domainResults = processDomainResponse(response);

      const suggestResponse = await axios.get("https://domain-api.softylus.com/getSuggest", {
        params: { domainName: title },
      });
      const suggestResults = suggestResponse.data.result.map(domain => ({
        domain: domain.domainName,
        status: domain.isAvailable ? 'available' : 'unavailable',
        pricing: domain.pricing[1].register, // Assuming shortest period pricing
      }));

      const filteredSuggestions = suggestResults.filter(suggestion => suggestion.domain !== title);

      const combinedResults = [domainResults[0], ...filteredSuggestions, ...domainResults.slice(1)];

      setDomainList(combinedResults);
      setHasSearched(true);
    } catch (error) {
      console.error('Error fetching domains:', error);
    } finally {
      setLoading(false);
    }
  };

  const processDomainResponse = (response) => {
    let domainResults = [];
  //  console.log(response.data.result[0].originalUnavailableDomain);
    if (response.data.result && (!response.data.result.error&& !response.data.result[0].originalUnavailableDomain)) {
      domainResults = response.data.result.map(domain => ({
        domain: domain.domainName,
        status: domain.isAvailable ? 'available' : 'unavailable',
        pricing: domain.pricing[1].register, // Assuming shortest period pricing
      }));
    } else {
      domainResults.push({
        domain: title,
        status: 'unavailable',
        pricing: null, 
      });
      console.log('title: ',title)
    }
    return domainResults;
  };

  const handleInputChange = (event) => {
    setTitle(event.target.value);
  };

  const handleKeyPress = async (event) => {
    if (event.key === "Enter" && title.trim() !== "") {
      await fetchDomains();
    }
  };

  const handleButtonClick = async () => {
    if (title.trim() !== "") {
      setLoading(true);
      await fetchDomains();
    }
  };

  const LoadingIndicator = () => (
    loading ? (
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader type="ThreeDots" color={`${props.color}`} height="40" width="60" />
      </div>
    ) : null
  );

  return (
    <div ref={searchRef}>
      <div className="mobile:my-4 my-8 bg-white grid grid-cols-5 mobile:grid-cols-4 p-1 mobile:p-0.5 border-2 border-gray-200 rounded-full relative">
        <input
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          value={title}
          className="outline-none mr-2 bg-none col-span-4 mobile:col-span-3 mobile:py-2 px-4 py-3 rounded-full"
          placeholder="Domain.com"
          id="domainChecker"
        />
        <button
          onClick={handleButtonClick}
          className="flex justify-center text-white font-bold bg-black rounded-full mobile:text-sm mobile:px-8 py-3"
        >
          <Search className="mr-2 mt-1 h-4 w-4 mobile:hidden" />
          Search
        </button>
      </div>
      {hasSearched && (
        <div className="bg-white rounded-xl pt-2">
          {domainList.map((item, index) => (
            <React.Fragment key={index}>
              <DomainListing
                domain={item.domain}
                status={item.status}
                pricing={item.pricing}
              />
            </React.Fragment>
          ))}
        </div>
      )}
      <LoadingIndicator />
      {domainList.length > 6 && (
        <button
          className="w-full py-3 mt-4 bg-gray-200 text-gray-700 rounded-xl hover:bg-gray-300"
        >
          LOAD MORE
        </button>
      )}
      <div className={` ${props.attr} flex justify-center mx-auto`}>
        <span
          className={`${props.attr} mobile:p-0.5 p-2 mx-0.5 text-sm  font-bold `}
        >
          .Com
        </span>
        <span
          className={`${props.attr} mobile:p-0.5 p-2 mx-0.5 text-sm  font-bold `}
        >
          .Co
        </span>
        <span
          className={`${props.attr} mobile:p-0.5 p-2 mx-0.5 text-sm  font-bold `}
        >
          .Net
        </span>
        <span
          className={`${props.attr} mobile:p-0.5 p-2 mx-0.5 text-sm  font-bold `}
        >
          .Org
        </span>
        <span
          className={`${props.attr} mobile:p-0.5 p-2 mx-0.5 text-sm  font-bold `}
        >
          .Club
        </span>
        <span
          className={`${props.attr} mobile:p-0.5 p-2 mx-0.5 text-sm  font-bold `}
        >
          .Design
        </span>
        <span
          className={`${props.attr} mobile:p-0.5 p-2 mx-0.5 text-sm font-bold `}
        >
          .Xyz{" "}
        </span>
      </div>
    </div>
  );
}

export default HomeDomainSearchInput;
HomeDomainSearchInput.propTypes = {
  attr: PropTypes.any,
  color: PropTypes.any,
};