import React from "react";
import PropTypes from "prop-types";
import AvailabelIcon from "./AvailableIcon";
import AddTocartDomain from "./AddTocartDomain";

function domainListing(props) {
  const { domain, status, pricing } = props;
  
  // Extract registration price
  const registrationPrice = pricing ;

  return (
    <div className="px-3 bg-white rounded-xl mt-5">
      <div className="h-16 bg-white w-full">
        <div className="flex flex-row items-center justify-between width-full m-auto">
          <div className="flex items-center">
            <AvailabelIcon status={status} />
            <p className="text-center px-3 text-gray-600 text-xl font-medium">
              {domain}
            </p>
          </div>

          <div className="flex items-center">
            {registrationPrice && status === 'available' && (
              <span className="mr-4 text-dark-600 font-bold">
                {registrationPrice}/yr
              </span>
            )}
              <AddTocartDomain
                domainName={domain}
                status={status}
              />
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
}

export default domainListing;

domainListing.propTypes = {
  domain: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  pricing: PropTypes.string,
};