import React from "react";
import PropTypes from "prop-types";

const AddTocartDomain = (props) => {
  if (props.status === "available")
    return (
      <a
        rel="noreferrer noopener"
        target="_blank"
        href={`https://client.hostylus.com/cart.php?a=add&domain=register&sld=${props.domainName}`}
      >
        <svg
          className={` float-right w-6 h-8 mt-1 m-auto fill-current text-green-500`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
        </svg>
      </a>
    );
  else if (props.status === "premium")
    return (
      <a
        rel="noreferrer noopener"
        target="_blank"
        href={`https://client.hostylus.com/cart.php?a=add&domain=register&sld=${props.domainName}`}
      >
        <svg
          className={` float-right w-6 h-8 mt-1 m-auto`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="#F2A205"
        >
          <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
        </svg>
      </a>
    );
  else
    return (
      <>
        <p className="text-center px-3 text-red-600 text-xl font-medium font-danger cursor-not-allowed">&nbsp;unavailable</p>
        <a href="#" className="cursor-not-allowed">
          <svg
            className={` float-right w-6 h-8 m-auto mt-1 fill-current text-gray-500`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clipRule="evenodd"
            />
          </svg>
        </a>
      </>
    );
};
export default AddTocartDomain;

AddTocartDomain.propTypes = {
  domainName: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};
