import React from "react";
import PropTypes from "prop-types";

const AvailabelIcon = (props) => {
  if (props.status === "available")
    return (
      <svg
        className={` float-right w-6 h-8 m-auto fill-current text-green-500`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clipRule="evenodd"
        />
      </svg>
    );
  else if (props.status === "premium")
    return (
      <svg
        className={` float-right w-6 h-8 m-auto fill-current text-green-500`}
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.1894 1.00294C13.0599 0.740717 12.7929 0.574707 12.5004 0.574707C12.208 0.574656 11.9409 0.740666 11.8114 1.00289L8.81467 7.07405C8.53286 7.64492 7.98822 8.04069 7.35816 8.13219L0.657911 9.10619C0.368496 9.14829 0.128078 9.35097 0.0376434 9.62912C-0.0526409 9.90723 0.0226307 10.2126 0.232058 10.4167L5.08003 15.143C5.53591 15.5874 5.74391 16.2277 5.63638 16.8551L4.49247 23.5273C4.44307 23.8155 4.56156 24.1068 4.79817 24.2786C5.03473 24.4506 5.34839 24.4732 5.60722 24.3371L11.6002 21.187C12.1637 20.8908 12.8369 20.8908 13.4006 21.1871L19.393 24.337C19.6519 24.4731 19.9655 24.4503 20.2021 24.2785C20.4387 24.1066 20.5572 23.8152 20.5077 23.5271L19.3633 16.8554C19.2556 16.2278 19.4637 15.5874 19.9197 15.1429L24.768 10.4168C24.9773 10.2126 25.0528 9.90728 24.9624 9.62918C24.872 9.35107 24.6316 9.14839 24.3422 9.10624L17.6421 8.1323C17.0121 8.04069 16.4674 7.64497 16.1856 7.074L13.1894 1.00294Z"
          fill="#F2A205"
        />
      </svg>
    );
  else
    return (
      <>
        <svg
          className={` float-right w-5 h-7 m-auto fill-current text-red-500`}
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0"
            fill="#f44336"
          />
          <path
            d="m350.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0"
            fill="#fafafa"
          />
        </svg>
      </>
    );
};
export default AvailabelIcon;

AvailabelIcon.propTypes = {
  status: PropTypes.any.isRequired, // must be a bool and defined
};
